import * as bootstrap from 'bootstrap';
import { Alert } from 'bootstrap';
import { Tooltip, Toast, Popover } from 'bootstrap';
import _ from 'lodash';
import mainImage from '../assets/main-image.png';
import portfolioImage from '../assets/portfolio-image.svg';

const inner = document.querySelector('.background');
const header = document.querySelector('header');
const portfolio = document.querySelector('#portfolio');
const reverseSections = document.querySelectorAll('.reverse');

const sectionHeight = _.map(reverseSections, (section) => {
  return { top: section.offsetTop, bottom: section.offsetTop + section.scrollHeight };
});

var backgroundIndex = 0;

const HEADER_HEIGHT = 75;
document.addEventListener('scroll', (_) => {
  if (portfolio.offsetTop - window.innerHeight > window.scrollY) {
    if (backgroundIndex !== 0) {
      inner.style.setProperty('background-image', `url(${mainImage})`, 'important');
      inner.style.setProperty('top', '0');
      backgroundIndex = 0;
    }
  } else {
    if (backgroundIndex !== 1) {
      inner.style.setProperty('background-image', `url(${portfolioImage})`, 'important');
      inner.style.setProperty('top', '20%');
      backgroundIndex = 1;
    }
  }

  let value = Math.max(window.scrollY - portfolio.offsetTop + window.innerHeight / 3, 0) / portfolio.offsetTop + 1;
  inner.style.transform = `scale(${value})`;

  let isContains = sectionHeight.filter((heightMap) => containScroll(window.scrollY, heightMap)).length > 0;
  if (isContains) {
    header.classList.remove('header-black');
    header.classList.add('header-white');
  } else {
    header.classList.remove('header-white');
    header.classList.add('header-black');
  }
});

function containScroll(scrollY, heightMap) {
  return scrollY >= heightMap.top - HEADER_HEIGHT && scrollY < heightMap.bottom;
}
